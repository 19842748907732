import React, { Fragment, useMemo, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  useTheme,
  IconButton,
  Typography,
  Divider,
  Tooltip,
  CircularProgress,
  AlertColor,
} from "@mui/material";
import { sec } from "../../auth/accessToken";
import QuotationDialogue from "../QuotationDialogue";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DownloadOutlined from "@mui/icons-material/DownloadOutlined";
import { Booking } from "models/booking.model";
import { useGetAuditsQuery, useGetExpectedProfitQuery } from "state/api";
import ProfitDisplay from "components/ProfitDisplay";
import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import BookingConfirmation from "components/BookingConfirmation";
import UpdateTermsAndConditions from "components/UpdateTermsAndConditions";
import { FormatListNumberedOutlined } from "@mui/icons-material";
import AlertSnackbar from "components/AlertSnackbar";
import HorizontalStepper from "components/HorizontalStepper";
import EmailForm from "components/EmailForm";
import { z } from "zod";
import CustomModel from "components/CustomModel";
import { CustomStepProp } from "components/HorizontalStepper/HorizontalStepper";

interface SnackbarState {
  open: boolean;
  color: AlertColor;
  message: string;
}
export interface QuotationTableProps {
  booking: Booking;
  refetch: Function;
}

const defaultSnackbarState: SnackbarState = {
  open: false,
  color: "success",
  message: "",
};

const QuotationTable: React.FC<QuotationTableProps> = ({
  booking,
  refetch,
}) => {
  const theme = useTheme();

  const userPermissions = useSelector((state: any) => state.global.permissions);

  const { refetch: auditLogsRefetch } = useGetAuditsQuery([
    "Booking",
    booking._id,
  ]);
  const { data: expectedProfit } = useGetExpectedProfitQuery(booking._id);

  const [openQuotation, setOpenQuotation] = useState(false);
  const handleClickOpenQuotation = () => {
    setOpenQuotation(true);
  };
  const handleCloseQuotation = () => {
    setOpenQuotation(false);
    refetch();
  };

  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
  const toggleTermsAndConditionsDialogue = (open: boolean) => {
    setTermsAndConditionsOpen(open);
    if (open) {
      refetch();
    }
  };

  const [modelOpen, setModelOpen] = useState(false);

  const handleOpenModel = () => {
    setModelOpen(true);
  };

  const handleCloseModel = () => {
    setModelOpen(false);
  };

  const [snackbarOpen, setSnackbarOpen] =
    useState<SnackbarState>(defaultSnackbarState);

  const quotationElement = useMemo(
    () => (
      <BookingConfirmation
        booking={booking}
        showQuotation={true}
        onlyShowQuotation={true}
      />
    ),
    [booking]
  );

  function updateApproval(
    sendEmail: true
  ): (data: Record<string, string | string[]>) => Promise<void>;
  function updateApproval(sendEmail?: false | undefined): () => Promise<void>;
  function updateApproval(sendEmail: boolean = false) {
    return async (data?: Record<string, string | string[]>) => {
      try {
        const formData = new FormData();
        formData.append("quotationApproval", "true");
        if (sendEmail && typeof data !== "undefined") {
          const blob = await pdf(quotationElement).toBlob();
          formData.append(
            "sendEmail",
            JSON.stringify({
              type: "quotation",
              to: data.to_emails,
              cc: data.cc_emails,
            })
          );
          if (blob !== null) {
            formData.append(
              "pdf",
              blob,
              `QuotationConfirmation_${booking.bookingNumber}.pdf`
            );
          }
        }
        const accessToken = await sec.getAccessTokenSilently()();

        await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${booking._id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        refetch();
        auditLogsRefetch();
        handleCloseModel();
        setSnackbarOpen({
          open: true,
          message: `Quotation approved ${sendEmail ? "and mail sent " : ""}successfully`,
          color: "success",
        });
      } catch (err) {
        console.error(err);
      }
    };
  }

  const profitElement = useMemo<CustomStepProp>(() => {
    const isProfitLow = expectedProfit / booking.numberOfContainers < 25;
    return {
      element: (
        <ProfitDisplay
          expectedProfit={expectedProfit}
          bookingCurrency={booking.bookingCurrency}
          numberOfContainers={booking.numberOfContainers}
        />
      ),
      label: "Profit Confirmation",
      error: isProfitLow,
      optional: isProfitLow ? (
        <Typography variant="caption" color="error">
          Profit Too Low
        </Typography>
      ) : undefined,
    };
  }, [booking.bookingCurrency, booking.numberOfContainers, expectedProfit]);

  const bookingConfirmationElement = useMemo<CustomStepProp>(
    () => ({
      element: (
        <PDFViewer
          style={{ width: "100%", height: "50vh", paddingBottom: "15px" }}
        >
          {quotationElement}
        </PDFViewer>
      ),
      label: "Quotation Confirmation",
    }),
    [quotationElement]
  );

  const emailFormElement = useMemo<CustomStepProp>(() => {
    const zodEmailSelectorValidation = z.array(
      z.string().email({ message: "Invalid email format" })
    );
    return {
      element: <EmailForm />,
      label: "Email Confirmation",
      validationSchema: z.object({
        to_emails: zodEmailSelectorValidation.min(1, {
          message: "This field has to be filled.",
        }),
        cc_emails: zodEmailSelectorValidation,
      }),
      defaultValues: {
        to_emails: [
          booking.bookingType === "Export"
            ? booking.consignor?.email
            : booking.consignee?.email,
        ],
        cc_emails: [],
      },
    };
  }, [booking.bookingType, booking.consignee?.email, booking.consignor?.email]);

  return (
    <Box>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              letterSpacing: "0.8px",
            }}
          >
            Quotation Details
          </Typography>
          {booking.quotationApproval === false ? (
            <Box>
              <Tooltip title="Edit Quotation">
                <IconButton onClick={handleClickOpenQuotation}>
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit T&Cs">
                <IconButton
                  onClick={() => toggleTermsAndConditionsDialogue(true)}
                >
                  <FormatListNumberedOutlined />
                </IconButton>
              </Tooltip>
              {userPermissions.includes("update:approval-quotation") && (
                <Tooltip title="Approve Quotation">
                  <IconButton onClick={booking.bookingType === "Export" ? handleOpenModel : updateApproval()}>
                    <AssignmentTurnedInOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          ) : (
            <Box>
              <PDFDownloadLink
                document={quotationElement}
                fileName={`${booking.bookingNumber}_Quotation.pdf`}
              >
                {({ loading }) =>
                  loading ? (
                    <CircularProgress size={"1rem"} />
                  ) : (
                    <Tooltip title="Download Quotation">
                      <IconButton>
                        <DownloadOutlined />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </PDFDownloadLink>
            </Box>
          )}
        </Box>
      </Box>
      <Table
        size="small"
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "none",
            color: theme.palette.secondary[100],
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            fontWeight: "600",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Charge Name</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Rate</TableCell>
            <TableCell>Base</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {["Buy Rates", "Sell Rates"].map((ratesType, ratesIndex) => (
            <Fragment key={ratesIndex}>
              <TableRow>
                <TableCell colSpan={4}>
                  <Divider>
                    <Typography variant="caption">{ratesType}</Typography>
                  </Divider>
                </TableCell>
              </TableRow>

              {(ratesType === "Buy Rates"
                ? booking.buyRates
                : booking.sellRates
              ).map((charge, index) => (
                <TableRow key={index}>
                  <TableCell>{charge.chargeName}</TableCell>
                  <TableCell>{charge.currency}</TableCell>
                  <TableCell>{charge.rate}</TableCell>
                  <TableCell>{charge.base}</TableCell>
                </TableRow>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
      {profitElement.element}
      <QuotationDialogue
        open={openQuotation}
        handleClose={handleCloseQuotation}
        id={booking._id}
        buyRates={booking.buyRates}
        sellRates={booking.sellRates}
      />
      <UpdateTermsAndConditions
        handleClose={() => toggleTermsAndConditionsDialogue(false)}
        open={termsAndConditionsOpen}
        id={booking._id}
        termsAndConditions={booking.quotationTermsAndConditions}
        type={"quotation"}
      />
      <CustomModel
        open={modelOpen}
        onClose={handleCloseModel}
        children={
          <HorizontalStepper
            steps={[
              profitElement,
              emailFormElement,
              bookingConfirmationElement,
            ]}
            onSubmit={updateApproval(true)}
          />
        }
      />
      <AlertSnackbar
        open={snackbarOpen.open}
        handleClose={() => setSnackbarOpen(defaultSnackbarState)}
        severity={snackbarOpen.color}
        message={snackbarOpen.message}
      />
    </Box>
  );
};

export default QuotationTable;
