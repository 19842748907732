import React, { useEffect, useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import * as XLSX from "xlsx";
import {
  InfoOutlined,
  EditOutlined,
  PaidOutlined,
  RecentActorsOutlined,
  RefreshOutlined,
  FilterAltOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import Header from "../../components/Header";
import InvoiceViewer from "components/InvoiceViewer";
import UpdateInvoiceDialogue from "components/UpdateInvoiceDialogue";
import { useGetAuditsQuery, useGetInvoicesQuery } from "../../state/api";
import axios from "axios";
import { sec } from "../../auth/accessToken";
import { Booking, Invoice } from "models/booking.model";
import { currencySymbols } from "dataAssets/constants";
import dayjs from "dayjs";
import {
  extractInvoiceType,
  setBookingTeams,
  totalInvoiceValue,
  useUserPermissions,
} from "utils/utils";
import CustomDataGrid from "components/CustomDataGrid";
import AuditLogsDialogue from "components/AuditLogsDialogue";
import { useSelector } from "react-redux";
import InvoicesFilterSideBar from "components/InvoicesFilterSideBar";

const Invoices = () => {
  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes("read:bookings-export");
  const hasImportPermission = userPermissions.includes("read:bookings-import");
  const teams = setBookingTeams(hasExportPermission, hasImportPermission);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [invoiceViewerOpen, setInvoiceViewerOpen] = useState(false);
  const [bookingData, setBookingData] = useState<Booking>({} as Booking);
  const [invoiceData, setInvoiceData] = useState<Invoice>({} as Invoice);
  const [updateInvoiceDialogueOpen, setUpdateInvoiceDialogueOpen] =
    useState(false);
  const [openAuditLogs, setOpenAuditLogs] = useState(false);

  const openUpdateInvoiceDialogue = async (
    bookingNumber: string,
    invoice: Invoice
  ) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/search/${bookingNumber}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setBookingData(response.data);
      setInvoiceData(invoice);
      setUpdateInvoiceDialogueOpen(true);
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateInvoiceDialogueClose = () => {
    setUpdateInvoiceDialogueOpen(false);
    refetch();
  };

  const openInvoiceViewer = async (bookingNumber: string, invoice: Invoice) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/search/${bookingNumber}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setBookingData(response.data[0]);
      setInvoiceData(invoice);
      setInvoiceViewerOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInvoiceViewerClose = () => {
    setInvoiceViewerOpen(false);
  };

  const settleInvoice = async (invoiceId: string, invoiceType: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${invoiceType}/settle/${invoiceId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  const {
    invoiceType,
    pendingApproval,
    invoiceOutstanding,
    underDispute,
    startDate,
    endDate,
    dateType,
    bookingTeam,
  } = useSelector((state: any) => state.global.invoicesFilters);

  const {
    data: invoices,
    isLoading,
    refetch,
    isFetching,
  } = useGetInvoicesQuery([
    invoiceType,
    pendingApproval,
    invoiceOutstanding,
    underDispute,
    [startDate, endDate],
    dateType,
    bookingTeam,
  ]);

  const { data: invoicesAuditLogs } = useGetAuditsQuery([
    "Invoice",
    invoiceData?._id,
  ]);

  const columns: GridColDef[] = [
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      flex: 0.4,
      valueGetter: (params) => {
        return (
          params.row.approvedInvoiceNumber ?? params.row.performaInvoiceNumber
        );
      },
    },
    {
      field: "bookingNumber",
      headerName: "Booking Number",
      flex: 0.3,
    },
    {
      field: "invoiceParty",
      headerName: "Invoice Party",
      flex: 0.5,
      valueGetter: (params) => {
        return params.row.vendor?.name ?? params.row.customer?.name ?? "N/A";
      },
    },
    {
      field: "vendorInvoiceNumber",
      headerName: "PI Number",
      flex: 0.3,
    },
    {
      field: "invoiceValue",
      headerName: "Invoice Value",
      flex: 0.3,
      valueGetter: (params) => {
        return `${
          currencySymbols[params.row.invoiceCurrency]
        } ${totalInvoiceValue(
          params.row.charges,
          params.row.numberOfContainers
        )}`;
      },
    },
    {
      field: "disputeDate",
      headerName: "Dispute Date",
      flex: 0.3,
      valueGetter: (params) => {
        return dayjs(params.row.disputeDetails.disputeDate).format(
          "DD/MM/YYYY"
        );
      },
    },
    {
      field: "disputeRemarks",
      headerName: "Dispute Remarks",
      flex: 0.5,
      valueGetter: (params) => {
        return params.row.disputeDetails.disputeRemarks;
      },
    },
    {
      field: "disputeFollowUp",
      headerName: "Follow Up",
      flex: 0.5,
      valueGetter: (params) => {
        return params.row.disputeDetails.followUp;
      },
    },
    {
      field: "invoiceSent",
      headerName: "Sent",
      flex: 0.2,
      valueGetter: (params) => {
        return params.row.invoiceSent ? "Yes" : "No";
      },
    },
    {
      field: "invoiceDate",
      headerName: "Creation Date",
      flex: 0.4,
      valueGetter: (params) => {
        return dayjs(params.row.invoiceDate).format("DD/MM/YYYY");
      },
    },
    {
      field: "approvalDate",
      headerName: "Approval Date",
      flex: 0.4,
      valueGetter: (params) => {
        return dayjs(params.row.approvalDate).format("DD/MM/YYYY");
      },
    },
    {
      field: "Actions",
      flex: 0.4,
      renderCell: (cellValues: any) => {
        return (
          <Box display={"flex"} justifyContent={"space-evenly"}>
            <Box>
              <Tooltip title="View Invoice">
                <IconButton
                  color="primary"
                  onClick={() =>
                    openInvoiceViewer(
                      cellValues.row.bookingNumber,
                      cellValues.row
                    )
                  }
                >
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              {cellValues.row.approvalPending && (
                <>
                  <Tooltip title="Edit Invoice">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        openUpdateInvoiceDialogue(
                          cellValues.row.bookingNumber,
                          cellValues.row
                        )
                      }
                    >
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>
            <Box>
              {cellValues.row.invoiceOutstanding &&
                !cellValues.row.approvalPending &&
                userPermissions.includes("update:settle-invoice") && (
                  <Tooltip title="Settle Invoice">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        settleInvoice(cellValues.row._id, invoiceType)
                      }
                    >
                      <PaidOutlined />
                    </IconButton>
                  </Tooltip>
                )}
            </Box>
            <Box>
              <Tooltip title="View Audit Logs">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setInvoiceData(cellValues.row);
                    setOpenAuditLogs(true);
                  }}
                >
                  <RecentActorsOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      invoiceNumber: true,
      bookingNumber: true,
      invoiceParty: true,
      invoiceValue: true,
      invoiceOutstanding: true,
      disputeRemarks: false,
      disputeDate: false,
      disputeFollowUp: false,
      invoiceDate: true,
      approvalDate: true,
      dueDate: true,
      vendorInvoiceNumber: false,
      actions: true,
    });

  useEffect(() => {
    if (invoiceType === "purchaseInvoices") {
      setColumnVisibilityModel((prevModel) => ({
        ...prevModel,
        vendorInvoiceNumber: true,
      }));
    } else {
      setColumnVisibilityModel((prevModel) => ({
        ...prevModel,
        vendorInvoiceNumber: false,
      }));
    }
  }, [invoiceType]);

  useEffect(() => {
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,
      approvalDate: !pendingApproval,
      underDispute: pendingApproval,
      invoiceSent: !pendingApproval,
    }));
  }, [pendingApproval]);

  useEffect(() => {
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,
      disputeDate: underDispute,
      disputeRemarks: underDispute,
      disputeFollowUp: underDispute,
    }));
  }, [underDispute]);

  const handleColumnVisibilityChange = (
    newModel: GridColumnVisibilityModel
  ) => {
    setColumnVisibilityModel(newModel);
  };

  const exportToExcel = (invoiceData: Invoice[]) => {
    const formattedData: any[] = [];

    invoiceData.forEach((invoice: Invoice) => {
      const invoiceRow = {
        "Invoice Number": invoice.approvedInvoiceNumber,
        "Invoice Date":
          extractInvoiceType(invoice.performaInvoiceNumber) === "PI"
            ? dayjs(invoice.vendorInvoiceDate).format("DD/MM/YY")
            : invoice.approvalDate
            ? dayjs(invoice.approvalDate).format("DD/MM/YY")
            : "TBD",
        "Invoice Due Date": ["DN", "CN"].some((invoiceType) =>
          invoice.performaInvoiceNumber.includes(invoiceType)
        )
          ? "N/A"
          : invoice.vendorInvoiceDate
          ? dayjs(invoice.vendorInvoiceDate)
              .add(invoice.vendorSnapshot?.creditPeriod ?? 0, "day")
              .format("DD/MM/YY")
          : invoice.approvalDate
          ? dayjs(invoice.approvalDate)
              .add(
                invoice.customerSnapshot?.creditPeriod ??
                  invoice.vendorSnapshot?.creditPeriod ??
                  0,
                "day"
              )
              .format("DD/MM/YY")
          : "TBD",
        "Invoice Party": invoice.customerSnapshot?.name,
        "Invoice Party Address": invoice.customerSnapshot?.address,
        "Voucher Type": "Sales",
        "Cost Category": invoice.bookingNumber.includes("EX")
          ? "Export"
          : "Import",
        "Job No": invoice.bookingNumber,
        "Charge Name": "",
        "Charge Amount": "",
        "Invoice Currency": invoice.invoiceCurrency,
        "Carrier Booking Number": invoice.bookingSnapshot.carrierBookingNumber,
        "Vessel / Voyage": `${invoice.bookingSnapshot.vesselName} ${invoice.bookingSnapshot.voyageNumber}`,
        "Port of Loading": invoice.bookingSnapshot.portOfLoading,
        "Port of Destination": invoice.bookingSnapshot.portOfDestination,
        "Number of Containers": `${invoice.numberOfContainers} X ${invoice.bookingSnapshot.containerType}`,
        "Cargo Description": invoice.bookingSnapshot.cargoDescription,
      };

      invoice.charges.forEach((charge, index) => {
        if (index === 0) {
          formattedData.push({
            ...invoiceRow,
            "Charge Name": charge.chargeName,
            "Charge Amount":
              charge.rate *
              charge.exchangeRate *
              (charge.base === "CN" ? invoice.numberOfContainers : 1),
          });
        } else {
          formattedData.push({
            "Invoice Number": "",
            "Invoice Date": "",
            "Invoice Due Date": "",
            "Invoice Party": "",
            "Invoice Party Address": "",
            "Voucher Type": "",
            "Cost Category": "",
            "Job No": "",
            "Charge Name": charge.chargeName,
            "Charge Amount":
              charge.rate *
              charge.exchangeRate *
              (charge.base === "CN" ? invoice.numberOfContainers : 1),
            "Invoice Currency": "",
          });
        }
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
    XLSX.writeFile(workbook, "invoices.xlsx");
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Header title="Invoices" subtitle="" />
        <Box>
          <Tooltip title="Refresh">
            <IconButton onClick={() => refetch()}>
              <RefreshOutlined />
            </IconButton>
          </Tooltip>
          {!pendingApproval && invoiceType === "salesInvoices" && (
            <Tooltip title="Export to Tally format">
              <IconButton onClick={() => invoices && exportToExcel(invoices)}>
                <FileDownloadOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Filter">
            <IconButton onClick={() => setDrawerOpen(true)}>
              <FilterAltOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <CustomDataGrid
        data={invoices}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        columnVisibilityModel={columnVisibilityModel}
        handleColumnVisibilityChange={handleColumnVisibilityChange}
        sortModel={[
          {
            field: "invoiceNumber",
            sort: "asc",
          },
        ]}
      />
      <InvoiceViewer
        open={invoiceViewerOpen}
        handleClose={handleInvoiceViewerClose}
        invoice={invoiceData}
        booking={bookingData}
        invoiceType={invoiceType}
      />
      <UpdateInvoiceDialogue
        open={updateInvoiceDialogueOpen}
        handleClose={handleUpdateInvoiceDialogueClose}
        invoice={invoiceData}
        invoiceType={invoiceType}
      />
      <AuditLogsDialogue
        open={openAuditLogs}
        handleClose={() => setOpenAuditLogs(false)}
        auditLogs={invoicesAuditLogs}
      />
      <InvoicesFilterSideBar
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        teams={teams}
      />
    </Box>
  );
};

export default Invoices;
