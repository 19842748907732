import { Customer, Site, Port, Vendor } from "./index.model";

export interface BookingBase {
  _id: string;
  bookingNumber: string;
  bookingStatus: BookingStatus;
  bookingTeam: TeamType;
  bookingType: "Import" | "Export";
  buyRates: Charge[];
  sellRates: Charge[];
  loadingTypeExport: "SD" | "CY";
  loadingTypeImport: "SD" | "CY";
  numberOfContainers: number;
  approximateWeight: number;
  containerType: string;
  doorFacing: "Cab" | "Rear";
  vesselVoyage: VesselVoyage;
  etd: string;
  eta: string;
  etaPOL: string;
  cargoDescription: string;
  deadlines: Deadlines;
  stepsCompleted: StepDetails[];
  bookingCurrency: string;
  quotationApproval: boolean;
  bookingTermsAndConditions: TermsAndConditions;
  quotationTermsAndConditions: TermsAndConditions;
  additionalBuyRates?: Charge[];
  additionalSellRates?: AdditionalCharge[];
  blStatusHistory?: BLStatus[];
  carrier?: Vendor;
  HSCode?: string;
  carrierQuotationReference?: string;
  carrierBookingNumber?: string;
  cargoValue?: CargoValue;
  haulageType?: HaulageType;
  mblNumber?: string;
  mblStatus?: "Seaway" | "Original";
  hblNumber?: string;
  hblStatus?: "Seaway" | "Original";
  blRemarks?: string;
  purchaseOrderNumber?: string;
  releasePin?: string;
  packages?: number;
  pickupDepot?: DepotSite[];
  dropoffDepot?: DepotSite[];
  transportSchedule?: TransportSchedule[];
  remarks?: Remarks;
  dateCreated?: string;
  bookingIncome?: {
    revenue: number;
    cost: number;
    profit: number;
  };
}

export interface Booking extends BookingBase {
  salesInvoices: Invoice[];
  creditNotes: Invoice[];
  purchaseInvoices: Invoice[];
  debitNotes: Invoice[];
  consignor?: Customer;
  consignee?: Customer;
  notifyParty?: Customer;
  portOfLoading?: Port;
  portOfDestination?: Port;
  placeOfDelivery?: Port;
  agent?: Vendor;
  transportSites?: TransportSite[];
}
export interface BookingRequest extends BookingBase {
  portOfLoading: string;
  portOfDestination: string;
  consignor: string;
  consignee?: string;
  notifyParty?: string;
  placeOfDelivery?: string;
  agent?: string;
  transportSites?: {
    site: string;
    containers: number;
  }[];
}

export interface TransportSite {
  site?: Site;
  containers: number;
}

export interface DepotSite extends TransportSite {
  reference?: string;
}

export interface StepDetails {
  dbRef: StepDbRef;
  status: StepStatus;
  ref?: Record<string, unknown>;
  type: StepType;
}

export interface CargoValue {
  currency: string;
  value: number;
}

export interface VesselVoyage {
  vesselName: string;
  voyageNumber: string;
  vesselFlag: string;
}

export interface BLStatus {
  status: string;
  statusDate: string;
  followUp: string;
  followUpDate: string;
}

export enum BookingStatus {
  Ongoing = "Ongoing",
  Cancelled = "Cancelled",
  Merged = "Merged",
  Completed = "Completed",
  All = "All",
}

export enum StepStatus {
  Processing = "processing",
  Completed = "completed",
  Failed = "failed",
}

export enum StepType {
  Manual = "manual",
  Api = "api",
}

export enum StepDbRef {
  CarrierBooking = "CBK",
  VGM = "VGM",
  SIInstruction = "SII",
  UCR = "UCR",
  PrepareInvoice = "PEI",
  DispatchBL = "DBL",
  PreparePurchaseInvoice = "PPI",
  PaymentToCarrier = "PTC",
  ObtainReleasePin = "ORP",
  PaymentToHMRC = "PTH",
  PrepareSalesInvoice = "PSI",
}

export enum TeamType {
  Rockers = "Rockers",
  Sharks = "Sharks",
  Falcons = "Falcons",
  All = "All",
}

export enum DeadlineType {
  SI = "SI",
  VGM = "VGM",
  UCR = "UCR",
  earliestRelease = "earliestRelease",
  earliestGateIn = "earliestGateIn",
  GateIn = "GateIn",
}

export enum HaulageType {
  Merchant = "Merchant",
  Carrier = "Carrier",
  Combined = "Combined",
}

export type Deadlines = Record<DeadlineType, string>;

export interface Charge {
  _id: string;
  chargeName: string;
  currency: string;
  rate: number;
  base: string;
}

export interface AdditionalCharge extends Charge {
  raised: boolean;
}

export interface InvoiceCharge extends Charge {
  exchangeRate: number;
}

export interface DisputeDetails {
  underDispute: boolean;
  disputeDate: string;
  disputeRemarks: string;
  disputeFollowUp: string;
}

export interface TransportSchedule {
  _id?: string;
  site?: string;
  date?: string;
  time?: string;
  hauler: string;
  reference?: string;
  containerNumber?: string;
  sealNumber?: string;
  weight?: string;
  remarks?: string;
  status?: TransportStatus;
}

export enum TransportStatus {
  Pending = "Pending",
  Delayed = "Delayed",
  Diverted = "Diverted",
  Cancelled = "Cancelled",
  Rebooked = "Rebooked",
  Completed = "Completed",
}

export interface LoadingData
  extends TransportSchedule,
    Pick<Booking, "bookingNumber" | "consignor"> {
  bookingId: Pick<Booking, "_id">;
}

export interface LoadPoints {
  loadPoint: string;
  containers: number;
}

export type Remarks = { remark: string }[];

export type TermsAndConditions = { termsAndCondition: string }[];

export interface InvoicePartySnapshot {
  name: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  creditPeriod: number;
}

export interface BookingSnapshot {
  carrierBookingNumber: string;
  cargoDescription: string;
  containerType: string;
  portOfLoading: string;
  portOfDestination: string;
  placeOfDelivery: string;
  etd: string;
  eta: string;
  vesselName: string;
  voyageNumber: string;
  containerNumbers: string[];
}

export interface Invoice {
  _id: string;
  performaInvoiceNumber: string;
  charges: InvoiceCharge[];
  numberOfContainers: number;
  invoiceDate: string;
  invoiceCurrency: string;
  approvalPending: boolean;
  invoiceOutstanding: boolean;
  bookingNumber: string;
  invoiceSent: boolean;
  bookingSnapshot: BookingSnapshot;
  customerSnapshot: InvoicePartySnapshot;
  vendorSnapshot: InvoicePartySnapshot;
  thirdPartySnapshot: string;
  customer?: Customer;
  approvalDate?: string;
  remarks?: string;
  approvedInvoiceNumber?: string;
  vendor?: Vendor;
  vendorInvoiceNumber?: string;
  vendorInvoiceDate?: string;
  disputeDetails?: DisputeDetails;
}

export interface AuditLog {
  _id: string;
  actor: string;
  action: string;
  resource: string;
  resourceId: string;
  timestamp: string;
}
