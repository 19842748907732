import React, { useState, useCallback, useMemo } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import ButtonStepper from "./ButtonStepper";
import BaseStepper, { CustomStepLabelProps } from "./BaseStepper";

export interface CustomStepProp extends CustomStepLabelProps {
  validationSchema?: z.Schema;
  defaultValues?: Record<string, string | string[] | unknown[]>;
  element: React.JSX.Element;
}

export interface HorizontalStepsProps {
  steps: CustomStepProp[];
  onSubmit: SubmitHandler<Record<string, string | string[]>>;
}

const HorizontalStepper: React.FC<HorizontalStepsProps> = ({
  steps,
  onSubmit,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const currentValidationSchema =
    steps[activeStep - 1].validationSchema ?? z.unknown();
  const currentDefaultValue = useMemo(
    () =>
      steps.reduce((acc, val) => {
        if (typeof val.defaultValues !== "undefined") {
          acc = {
            ...acc,
            ...val.defaultValues,
          };
        }
        return acc;
      }, {}),
    [steps]
  );
  const isLastStep = activeStep === steps.length;
  const formProps = useForm({
    resolver: zodResolver(currentValidationSchema),
    defaultValues: currentDefaultValue,
  });
  const { handleSubmit, formState } = formProps;

  const _handleSubmit = useCallback(async () => {
    console.log("submit is called");
    if (isLastStep) {
      return handleSubmit(onSubmit)();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [handleSubmit, isLastStep, onSubmit]);

  const _handleBack = useCallback(async () => {
    if (activeStep === 1) {
      return;
    }
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  return (
    <Box
      minHeight={"50vh"}
      minWidth={"50vw"}
      display="flex"
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"stretch"}
      textAlign={"center"}
    >
      <Box>
        <Typography variant="h3" gutterBottom>
          Quotation Confirmation
        </Typography>
      </Box>
      <Box pt={2}>
        <BaseStepper
          activeStep={activeStep}
          steps={steps.map(
            ({ element, validationSchema, defaultValues, ...remaining }) =>
              remaining
          )}
        />
      </Box>

      <Divider sx={{ mt: 1 }} />

      <Box p={2}>
        <FormProvider {...formProps}>
          <form onSubmit={handleSubmit(_handleSubmit)}>
            {steps[activeStep - 1].element}
            <ButtonStepper
              steps={steps}
              activeStep={activeStep}
              onClickBack={_handleBack}
              loading={formState.isSubmitting}
            />
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default HorizontalStepper;
