import * as React from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { Controller, useFormContext } from "react-hook-form";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

const EmailForm: React.FC = () => {
  const { control } = useFormContext();
  return (
    <Stack gap={2} width={"100%"}>
      <Controller
        name="to_emails"
        control={control}
        render={({
          field: { value, onChange, onBlur, ref },

          fieldState: { error },
        }) => 
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              clearIcon={false}
              freeSolo
              multiple
              filterSelectedOptions
              onChange={(
                _event: unknown,
                item: typeof control._defaultValues.to_emails | null
              ) => {
                onChange(item);
              }}
              value={value}
              options={control._defaultValues.to_emails}
              renderTags={(value: string[], props) =>
                value?.map((option, index) => (
                  <Chip
                    sx={{
                      borderColor:
                        Array.isArray(error) && error[index]
                          ? "error.main"
                          : "default",
                    }}
                    variant="outlined"
                    label={option}
                    avatar={<Avatar>{option.substring(0, 1)}</Avatar>}
                    {...props({ index })}
                  />
                ))
              }
              renderOption={(props, option) => {
                return (
                  <Box
                    component="li"
                    sx={{ "& div": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Avatar>{option.substring(0, 1)}</Avatar>
                    {option}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(error)}
                  onBlur={onBlur}
                  inputRef={ref}
                  label={"To Emails"}
                />
              )}
            />

            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {Array.isArray(error)
                ? error.find((v) => v?.message).message
                : error?.message ?? ""}
            </FormHelperText>
          </FormControl>
        }
      />
      <Controller
        name="cc_emails"
        control={control}
        render={({
          field: { value, onChange, onBlur, ref },

          fieldState: { error },
        }) => 
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              clearIcon={false}
              freeSolo
              multiple
              filterSelectedOptions
              onChange={(
                _event: unknown,
                item: typeof control._defaultValues.cc_emails | null
              ) => {
                onChange(item);
              }}
              value={value}
              options={control._defaultValues.cc_emails}
              renderTags={(value: string[], props) =>
                value?.map((option, index) => (
                  <Chip
                    sx={{
                      borderColor:
                        Array.isArray(error) && error[index]
                          ? "error.main"
                          : "default",
                    }}
                    variant="outlined"
                    label={option}
                    avatar={<Avatar>{option.substring(0, 1)}</Avatar>}
                    {...props({ index })}
                  />
                ))
              }
              renderOption={(props, option) => {
                return (
                  <Box
                    component="li"
                    sx={{ "& div": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Avatar>{option.substring(0, 1)}</Avatar>
                    {option}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(error)}
                  onBlur={onBlur}
                  inputRef={ref}
                  label={"CC Emails"}
                />
              )}
            />

            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {Array.isArray(error)
                ? error.find((v) => v?.message).message
                : error?.message ?? ""}
            </FormHelperText>
          </FormControl>
        }
      />
    </Stack>
  );
};

export default EmailForm;
