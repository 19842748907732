import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";

function SubTable(props: { subTableMap: Map<string, Map<string, string[]>> }) {
  const { subTableMap } = props;
  const [open, setOpen] = React.useState(false);
  const subTableIterator = subTableMap?.entries();
  if (!subTableIterator) {
    return null;
  }
  const innerTableRows = Array.from(subTableMap).reduce(
    (acc, [_key, value]) => {
      [...value.values()].forEach((valArr) => {
        valArr.forEach((finalVal, idx) => {
          if (!acc[idx]) {
            acc.push([]);
          }
          acc[idx].push(finalVal);
        });
      });
      return acc;
    },
    [] as string[][]
  );
  return Array.from(subTableMap).map(([key, value], tableIdx) => (
    <React.Fragment key={tableIdx}>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={`${tableIdx}-row1`}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {key}
        </TableCell>
      </TableRow>
      <TableRow key={`${tableIdx}-row2`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {[...value.keys()].map((head, innerIdx) => (
                      <TableCell key={`${tableIdx}-${innerIdx}-head`}>{head}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {innerTableRows.map((valArr, rowIndex) => (
                    <TableRow key={`${tableIdx}-${rowIndex}-row`}>
                      {valArr.map((finalVal, cellIndex) => (
                        <TableCell component="th" scope="row" key={`${tableIdx}-${rowIndex}-${cellIndex}-cell`}>
                          {finalVal}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  ));
}

function Row(props: { row: [string, string]; hasSubTable: boolean }) {
  const { row, hasSubTable } = props;
  return (
    <TableRow>
      {hasSubTable ? <TableCell></TableCell> : null}
      <TableCell>{row[0]}</TableCell>
      <TableCell>{row[1]}</TableCell>
    </TableRow>
  );
}

const ConfirTable = (content: Record<string, string>) => {
  const subTableMap: Map<string, Map<string, string[]>> = new Map();
  Object.entries(content).forEach((row) => {
    if (/\[.*\]/.test(row[0])) {
      const splitVal = row[0].split(/\[.*\]/);
      if (!subTableMap.has(splitVal[0])) {
        subTableMap.set(splitVal[0], new Map());
      }
      const innerMap = subTableMap.get(splitVal[0]);
      if (!innerMap?.has(splitVal[1])) {
        innerMap?.set(splitVal[1], []);
      }
      subTableMap.get(splitVal[0])?.get(splitVal[1])?.push(row[1]);
      delete content[row[0]];
    }
  });
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {subTableMap.size > 0 ? <TableCell /> : null}
              <TableCell>Key</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(content).map((entry, idx) => (
              <Row key={idx} row={entry} hasSubTable={subTableMap.size > 0} />
            ))}
            <SubTable subTableMap={subTableMap} />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ConfirTable;
