import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
// @ts-ignore
import FontRubikRegular from "../../assets/fonts/Rubik-Regular.ttf";
// @ts-ignore
import FontRubikMedium from "../../assets/fonts/Rubik-Medium.ttf";
// @ts-ignore
import FontRubikSemiBold from "../../assets/fonts/Rubik-SemiBold.ttf";
// @ts-ignore
import FontRubikBold from "../../assets/fonts/Rubik-Bold.ttf";
// @ts-ignore
import ReudanLogo from "../../assets/REUDAN LOGO.png";
import { Booking, Invoice } from "models/booking.model";
import { invoiceTypes, reudanAddress } from "dataAssets/constants";
import { totalInvoiceValue } from "utils/utils";

Font.register({
  family: "Rubik",
  fonts: [
    { src: FontRubikRegular },
    { src: FontRubikMedium, fontWeight: "medium" },
    { src: FontRubikSemiBold, fontWeight: "semibold" },
    { src: FontRubikBold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Rubik",
    fontSize: 9,
    position: "relative",
  },
  sectionHeading: {
    fontSize: 12,
    textTransform: "uppercase",
    letterSpacing: 1,
    marginVertical: 10,
    fontWeight: "semibold",
  },
  dataRow: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginBottom: 5,
  },
  dataCell: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bookingCell: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  quotationCell: {
    width: "25%",
    textAlign: "center",
  },
  invoiceDates: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 3,
  },
  fixed: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: "20px 35px",
    position: "absolute",
  },
  watermark: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-160%, -50%)",
    opacity: 0.2,
    fontSize: 120,
    fontWeight: "bold",
    color: "gray",
    pointerEvents: "none",
  },
  watermarkText: {
    transform: "rotate(-45deg)",
  },
  pageContent: {
    flex: 1,
    marginBottom: 80,
  },
  chargesSection: {
    height: 265,
    marginBottom: 10,
    overflow: "hidden",
  },
});

export interface InvoiceConfirmationProps {
  invoice: Invoice;
  booking: Booking;
  invoiceType: string;
}

const InvoiceConfirmation: React.FC<InvoiceConfirmationProps> = ({
  invoice,
  booking,
  invoiceType,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        {invoice.approvalPending && (
          <View style={styles.watermark}>
            <Text style={styles.watermarkText}>DRAFT</Text>
          </View>
        )}
        <View style={styles.pageContent}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: 24,
                  fontWeight: "bold",
                }}
              >
                Reudan International Ltd
              </Text>
              <Text
                style={{
                  fontSize: 8,
                }}
              >
                {`${reudanAddress} VAT No:  GB 309 2413 26000`}
              </Text>
              <View>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "semibold",
                    marginTop: 10,
                  }}
                >
                  {`${invoiceTypes[invoiceType]}: ${
                    invoice.approvedInvoiceNumber ??
                    invoice.performaInvoiceNumber
                  }`}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "12.5%",
              }}
            >
              <Image src={ReudanLogo} />
            </View>
          </View>
          <View
            style={{
              borderTop: "1px solid black",
            }}
          >
            <Text style={styles.sectionHeading}>{`${
              invoice.vendor ? "Vendor" : "Customer"
            } Details`}</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                gap: 10,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  width: "50%",
                }}
              >
                {invoice.vendor ? (
                  <>
                    <Text>{`Vendor: ${invoice.vendorSnapshot?.name}`}</Text>
                    <Text>{`Address: ${invoice.vendorSnapshot?.address}, ${invoice.vendorSnapshot?.city}, ${invoice.vendorSnapshot?.postcode}, ${invoice.vendorSnapshot?.country}`}</Text>
                  </>
                ) : (
                  <>
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >{`Customer: ${invoice.customerSnapshot?.name}`}</Text>
                    <Text>{`Address: ${invoice.customerSnapshot?.address}, ${invoice.customerSnapshot?.city}, ${invoice.customerSnapshot?.postcode}, ${invoice.customerSnapshot?.country}`}</Text>
                    <Text>{`${
                      booking.bookingType === "Export"
                        ? "Consignee"
                        : "Consignor"
                    }: ${invoice.thirdPartySnapshot}`}</Text>
                  </>
                )}
              </View>
              <View
                style={{
                  width: "50%",
                }}
              >
                <View style={styles.invoiceDates}>
                  <Text>{`${
                    invoiceType !== "creditNotes" ? "Invoice Date" : "CN Date"
                  }`}</Text>
                  <Text>
                    {invoice.approvalDate
                      ? dayjs(invoice.approvalDate).format("DD/MM/YYYY")
                      : `TBA`}
                  </Text>
                </View>
                {invoiceType === "purchaseInvoices" && (
                  <>
                    <View style={styles.invoiceDates}>
                      <Text>{"Purchase Invoice Number:"}</Text>
                      <Text>{invoice.vendorInvoiceNumber}</Text>
                    </View>
                    <View style={styles.invoiceDates}>
                      <Text>{"Vendor Invoice Date:"}</Text>
                      <Text>
                        {dayjs(invoice.vendorInvoiceDate).format("DD/MM/YYYY")}
                      </Text>
                    </View>
                  </>
                )}
                {(invoiceType === "salesInvoices" ||
                  invoiceType === "purchaseInvoices") && (
                  <View style={styles.invoiceDates}>
                    <Text>{"Due Date:"}</Text>
                    <Text>
                      {invoiceType === "purchaseInvoices"
                        ? dayjs(invoice.vendorInvoiceDate)
                            .add(
                              invoice.vendorSnapshot?.creditPeriod ?? 0,
                              "day"
                            )
                            .format("DD/MM/YYYY")
                        : invoice.approvalDate
                        ? dayjs(invoice.approvalDate)
                            .add(
                              invoice.customerSnapshot?.creditPeriod ??
                                invoice.vendorSnapshot?.creditPeriod ??
                                0,
                              "day"
                            )
                            .format("DD/MM/YYYY")
                        : `TBA`}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>
          <View
            style={{
              borderTop: "1px solid black",
              paddingBottom: 10,
            }}
          >
            <Text style={styles.sectionHeading}>Shipment Details</Text>
            <View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{"Booking No:"}</Text>
                  <Text>{invoice.bookingNumber}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{"POL / ETD:"}</Text>
                  <Text>{`${invoice.bookingSnapshot.portOfLoading} / ${dayjs(
                    invoice.bookingSnapshot.etd
                  ).format("DD-MM-YYYY")}`}</Text>
                </View>
              </View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{"Carrier Booking No:"}</Text>
                  <Text>{invoice.bookingSnapshot.carrierBookingNumber}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{"POD / ETA:"}</Text>
                  <Text>{`${
                    invoice.bookingSnapshot.portOfDestination
                  } / ${dayjs(invoice.bookingSnapshot.eta).format(
                    "DD-MM-YYYY"
                  )}`}</Text>
                </View>
              </View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{"Cargo Details:"}</Text>
                  <Text
                    style={{
                      width: "70%",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      textAlign: "right",
                    }}
                  >
                    {invoice.bookingSnapshot.cargoDescription}
                  </Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{"FPOD:"}</Text>
                  <Text>{invoice.bookingSnapshot.placeOfDelivery}</Text>
                </View>
              </View>
              <View style={styles.dataRow}>
                <View style={styles.dataCell}>
                  <Text>{"No. of Containers:"}</Text>
                  <Text>{`${invoice.numberOfContainers} x ${invoice.bookingSnapshot.containerType}`}</Text>
                </View>
                <View style={styles.dataCell}>
                  <Text>{"Vessel V. Voyage:"}</Text>
                  <Text>{`${invoice.bookingSnapshot.vesselName} ${invoice.bookingSnapshot.voyageNumber}`}</Text>
                </View>
              </View>
              <View>
                <Text>{`Remarks: ${invoice.remarks}`}</Text>
              </View>
              <View>
                <Text>{`Container Numbers: ${invoice.bookingSnapshot.containerNumbers
                  .map((container) => container)
                  .join("/ ")}`}</Text>
              </View>
            </View>
          </View>
          <View style={styles.chargesSection}>
            <View
              style={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Text style={styles.sectionHeading}>Charges</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: 10,
                  borderBottom: "0.5px solid black",
                }}
              >
                <View
                  style={{
                    width: "50%",
                    textAlign: "left",
                  }}
                >
                  <Text>Charge Name</Text>
                </View>
                <View style={styles.quotationCell}>
                  <Text>Currency</Text>
                </View>
                <View style={styles.quotationCell}>
                  <Text>Rate</Text>
                </View>
                <View
                  style={{
                    width: "20%",
                    textAlign: "right",
                  }}
                >
                  <Text>Base</Text>
                </View>
                <View
                  style={{
                    width: "30%",
                    textAlign: "right",
                  }}
                >
                  <Text>{`Amount (${invoice.invoiceCurrency})`}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  padding: 10,
                  borderBottom: "0.5px solid black",
                }}
              >
                {invoice.charges.map((charge, index) => (
                  <View
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <View
                      style={{
                        width: "50%",
                        textAlign: "left",
                      }}
                    >
                      <Text>{charge.chargeName}</Text>
                    </View>
                    <View style={styles.quotationCell}>
                      <Text>{charge.currency}</Text>
                    </View>
                    <View style={styles.quotationCell}>
                      <Text>{charge.rate}</Text>
                    </View>
                    <View
                      style={{
                        width: "20%",
                        textAlign: "right",
                      }}
                    >
                      <Text>{charge.base}</Text>
                    </View>
                    <View
                      style={{
                        width: "30%",
                        textAlign: "right",
                      }}
                    >
                      <Text>
                        {(charge.base === "CN"
                          ? charge.rate *
                            invoice.numberOfContainers *
                            charge.exchangeRate
                          : charge.rate * charge.exchangeRate
                        ).toFixed(2)}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: 10,
                }}
              >
                <Text>
                  {`Total: ${invoice.invoiceCurrency} ${totalInvoiceValue(
                    invoice.charges,
                    invoice.numberOfContainers
                  )}`}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {(invoiceType === "salesInvoices" || invoiceType === "debitNotes") && (
          <View style={styles.fixed} fixed>
            <View
              style={{
                borderTop: "1px solid black",
                fontSize: 7,
              }}
            >
              <Text style={styles.sectionHeading}>E & O E</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  paddingBottom: 5,
                }}
              >
                <Text>
                  - If there is any discrepancy, kindly contact our accounts
                  department within 3 days in writing from this invoice date
                  otherwise all charges are deemed to be correct.
                </Text>
                <Text>- Penalties for late payment as applicable.</Text>
                <Text>
                  - All cargoes must be insured until its final destination, we
                  as carrier will not be responsible for any damage/claim during
                  cargo in transit.
                </Text>
              </View>
            </View>
            <View
              style={{
                borderTop: "1px solid black",
                fontSize: 8,
              }}
            >
              <Text style={styles.sectionHeading}>Bank A/C Details</Text>
              <View>
                <View style={styles.dataRow}>
                  <View style={styles.dataCell}>
                    <Text>{"BANK:"}</Text>
                    <Text>{`BARCLAYS BANK PLC.`}</Text>
                  </View>
                  <View style={styles.dataCell}>
                    {invoice.invoiceCurrency === "USD" && (
                      <>
                        <Text>{"USD A/C NO:"}</Text>
                        <Text>{`56551599`}</Text>
                      </>
                    )}
                    {invoice.invoiceCurrency === "GBP" && (
                      <>
                        <Text>{"GBP A/C NO:"}</Text>
                        <Text>{`10639729`}</Text>
                      </>
                    )}
                  </View>
                </View>
                <View style={styles.dataRow}>
                  <View style={styles.dataCell}>
                    <Text>{"BRANCH:"}</Text>
                    <Text>{`LONDON`}</Text>
                  </View>
                  <View style={styles.dataCell}>
                    <Text>{"IBAN:"}</Text>
                    {invoice.invoiceCurrency === "USD" && (
                      <Text>{`GB64 BUKB 2069 1556 5515 99`}</Text>
                    )}
                    {invoice.invoiceCurrency === "GBP" && (
                      <Text>{`GB33 BUKB 2069 1510 6397 29`}</Text>
                    )}
                  </View>
                </View>
                <View style={styles.dataRow}>
                  <View style={styles.dataCell}>
                    <Text>{"ADDRESS:"}</Text>
                    <Text>{`1ST FLOOR, 9 PORTMAN SQUARE, LONDON, W1A 3AL`}</Text>
                  </View>
                  <View style={styles.dataCell}>
                    <Text>{"SORT CODE:"}</Text>
                    <Text>{`206915`}</Text>
                  </View>
                </View>
                <View style={styles.dataRow}>
                  <View style={styles.dataCell}>
                    <Text>{""}</Text>
                    <Text>{``}</Text>
                  </View>
                  <View style={styles.dataCell}>
                    <Text>{"SWIFT BIC:"}</Text>
                    <Text>{`BUKBGB22`}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                padding: "5px 0",
                fontSize: 5,
                borderBottom: "1px solid black",
              }}
            >
              <Text>
                THIS INVOICE IS COMPUTER GENERATED. NO SIGNATURE IS REQUIRED
              </Text>
              <Text>
                ALL BUSINESS ARE UNDERTAKEN SUBJECT TO THE STANDARD TRADING
                CONDITIONS OF BRITISH INTERNATIONAL FREIGHT ASSOCIATION. A COPY
                OF WHICH CAN BE OBTAINED FROM
                HTTP://WWW.BIFA.ORG/CONTENT/TRADING.ASPX
              </Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default InvoiceConfirmation;
